import { useCallback, useRef, useState } from 'react'
import { Link, TextContent, Badge } from '@amzn/awsui-components-react'
import { ColDef, ColGroupDef } from 'ag-grid-community'

export const DEFAULT_PAGE_SIZE = 50

export const STATUS_TO_BADGE_MAP = {
    OPEN: 'blue',
    CLOSED: 'grey',
}

export const DIRECTORY_PREFIX = {
    REVISION: 'rev#',
    TEAM_PROGRAM: 'tp#',
}

export interface DirectoryPayload {
    business_entity_id: string
    revision_id: string
    revision_title: string
    requester: string
    date_from: string
}

export const formatRevisionOptions = (rev) => {
    if (!rev) return

    return {
        label: (
            <TextContent>
                <p>
                    {`${rev.revision_title} - ${new Date(Number(rev.revision_id)).toLocaleString('default', { month: 'short', year: 'numeric' })}`}{' '}
                    <Badge color={STATUS_TO_BADGE_MAP[rev.revision_status]}>
                        {rev.revision_status}
                    </Badge>
                </p>
            </TextContent>
        ),
        value: rev,
    }
}

export const useLoadingState = () => {
    const loadingRef = useRef(0)
    const [isLoading, setIsLoading] = useState(false)

    const startLoading = useCallback(() => {
        loadingRef.current++
        setIsLoading(true)
    }, [])

    const stopLoading = useCallback(() => {
        loadingRef.current = Math.max(0, loadingRef.current - 1)
        if (loadingRef.current === 0) {
            setIsLoading(false)
        }
    }, [])

    return {
        isLoading,
        startLoading,
        stopLoading,
    }
}

const linkCellRenderer = (params) => {
    return (
        params.value && (
            <Link href={params.getLink(params)} external={params.external}>
                {params.value}
            </Link>
        )
    )
}

export const getDirectoryColumnDefs = (): (ColDef | ColGroupDef)[] => [
    { field: 'team_id', headerName: 'Team ID', hide: true },
    { field: 'team_name', headerName: 'Team' },
    { field: 'parent_program_id', headerName: 'Program ID', hide: true },
    { field: 'program_name', headerName: 'Program' },
    {
        field: 'wiki_link',
        headerName: 'Wiki Link',
        cellRenderer: linkCellRenderer,
        cellRendererParams: {
            getLink: (params) => params.data.wiki_link,
            external: true,
        },
    },
    { field: 'description', headerName: 'Description of Support' },
    { field: 'stl_alias', headerName: 'STL' },
    { field: 'tpm_alias', headerName: 'TPM' },
    { field: 'pmt_alias', headerName: 'PMT' },
]
