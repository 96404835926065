import { useState } from 'react'
import {
    Box,
    Button,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react'

interface CreateRevisionProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    handleCreate: (title: string) => void
}

const CreateRevision = ({ visible, setVisible, handleCreate }: CreateRevisionProps) => {
    const [revisionTitle, setRevisionTitle] = useState<string>('')

    const handleDismiss = () => {
        setVisible(false)
        setRevisionTitle('')
    }

    return (
        <Modal
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header='New Revision'
            visible={visible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => {
                                handleCreate(revisionTitle)
                                handleDismiss()
                            }}
                            disabled={!revisionTitle}
                        >
                            Add
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Container>
                <SpaceBetween size={'xs'}>
                    <Header>Revision Details</Header>
                    <FormField label='Revision Title'>
                        <Input
                            value={revisionTitle}
                            onChange={(event) => setRevisionTitle(event.detail.value)}
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
        </Modal>
    )
}

export default CreateRevision
