import { useCallback, useState, useEffect } from 'react'
import {
    Box,
    Container,
    ContentLayout,
    Flashbar,
    SelectProps,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import { AlertTypes } from '../../Constant'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { convertToLocalTime, decompressResponse } from '../reusable/Utils'
import CreateRevision from './CreateRevision'
import DirectoryList from './DirectoryList'
import { DirectoryPayload, useLoadingState, formatRevisionOptions } from './DirectoryUtil'

const Directory = () => {
    const apiContext = useAppContext()
    const apiClient = apiContext.apiClient
    const userAlias = apiContext.userAlias
    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [revisionOptions, setRevisionOptions] = useState<SelectProps.Option[]>([])
    const [selectedRevision, setSelectedRevision] = useState<SelectProps.Option | undefined>(
        undefined,
    )

    const [isAllTeams, setIsAllTeams] = useState<boolean>(true)
    const [myTeams, setMyTeams] = useState<Map<string, any> | undefined>(undefined)
    const [allTeams, setAllTeams] = useState<Map<string, any> | undefined>(undefined)
    const [parentPrograms, setParentPrograms] = useState<Map<string, any> | undefined>(undefined)

    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.INFO)
    const [alertContent, setAlertContent] = useState<string>('')

    // fetches/posts data from the specified URL with loading state management
    // and executes the success callback with the res
    const { isLoading, startLoading, stopLoading } = useLoadingState()
    const fetchData = useCallback(
        (url: string, onSuccess: (res: any) => void, errMsg: string) => {
            startLoading()
            apiClient
                .get(url)
                .then(onSuccess)
                .catch((err) => {
                    console.error(err)
                    setAlertType(AlertTypes.ERROR)
                    setAlertContent(errMsg)
                })
                .finally(() => {
                    stopLoading()
                })
        },
        [startLoading, stopLoading],
    )
    const postData = useCallback(
        (url: string, payload: DirectoryPayload, onSuccess: (res: any) => void, errMsg: string) => {
            startLoading()
            apiClient
                .post(url, JSON.stringify(payload))
                .then(onSuccess)
                .catch((err) => {
                    console.error(err)
                    setAlertType(AlertTypes.ERROR)
                    setAlertContent(errMsg)
                })
                .finally(() => {
                    stopLoading()
                })
        },
        [startLoading, stopLoading],
    )

    useEffect(() => {
        fetchParentPrograms()
    }, [])

    const fetchParentPrograms = () => {
        const processParentProgramRes = (res: any) => {
            const parentPrograms = decompressResponse(res.data)
            const parentProgramList = parentPrograms.map((prog) => [prog.parent_program_id, prog])
            setParentPrograms(new Map(parentProgramList))
        }
        fetchData(`/programs?metadata=false`, processParentProgramRes, 'Failed to fetch programs')
    }

    useEffect(() => {
        fetchTeams()
        loadRevisions()
    }, [selectedBusinessEntity])

    const fetchTeams = useCallback(() => {
        if (!selectedBusinessEntity || !selectedBusinessEntity.id) return

        const myTeamsURL = `/user/${userAlias}/business-entity/${selectedBusinessEntity.id}/myteam`
        const processMyTeamsRes = (res: any) => {
            const myTeamList = res.data.map((team) => [team.team_id, team])
            setMyTeams(new Map(myTeamList))
        }
        fetchData(myTeamsURL, processMyTeamsRes, 'Failed to fetch my teams')

        const allTeamsURL = `/business-entity/${selectedBusinessEntity.id}/teams`
        const processAllTeamsRes = (res: any) => {
            const allTeamList = res.data.map((team) => [team.team_id, team])
            setAllTeams(new Map(allTeamList))
        }
        fetchData(allTeamsURL, processAllTeamsRes, 'Failed to fetch all teams')
    }, [selectedBusinessEntity])

    const loadRevisions = useCallback(async () => {
        if (!selectedBusinessEntity || !selectedBusinessEntity.id) return

        const processRevisionsRes = (res) => {
            const revisions = res.data
            revisions.sort((a: any, b: any) => Number(b.revision_id) - Number(a.revision_id))

            const options = revisions.map((rev) => formatRevisionOptions(rev))
            setRevisionOptions(options)
            setSelectedRevision(options?.[0])
        }
        fetchData(
            `business-entity/${selectedBusinessEntity.id}/directories`,
            processRevisionsRes,
            `failed to load directory revisions for ${selectedBusinessEntity.name}`,
        )
    }, [selectedBusinessEntity])

    const handleCreate = useCallback(
        (title: string) => {
            const current_time = Date.now()
            const six_month_time = new Date(current_time)
            six_month_time.setMonth(six_month_time.getMonth() - 6)

            const payload: DirectoryPayload = {
                business_entity_id: selectedBusinessEntity.id,
                requester: userAlias ?? '',
                revision_id: current_time.toString(),
                revision_title: title,
                date_from: convertToLocalTime(six_month_time),
            }

            const processCreate = () => {
                loadRevisions()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(`Successfully created directory revision ${title}`)
            }

            postData(
                `/directory/${payload.revision_id}`,
                payload,
                processCreate,
                `Failed to create directory revisio ${title}`,
            )
        },
        [selectedBusinessEntity],
    )

    return (
        <Box margin={'s'}>
            <ContentLayout
                header={
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'AR Directory',
                                    href: '',
                                },
                            ]}
                        />
                        {alertContent !== '' && (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertContent('')
                                        },
                                        dismissible: true,
                                        content: alertContent,
                                        type: alertType,
                                    },
                                ]}
                            />
                        )}
                    </SpaceBetween>
                }
            >
                <Container
                    fitHeight
                    header={
                        <Box color='text-status-inactive'>
                            <span style={{ fontWeight: 'bold' }}>Directions: </span>{' '}
                            <span>Take a moment to review your team's directory items below.</span>
                            <ol style={{ paddingLeft: 20, marginTop: 0 }}>
                                <li>
                                    Is this correct or is anything missing? Anything with red text
                                    has not been updated in XX time, please confirm it is up-to-date
                                    below
                                </li>
                                <li>
                                    Please include how your team supports the individual programs
                                    you allocate against in 15 words or less for each item in the
                                    "Description of Support" section
                                </li>
                                <li>
                                    Confirm if any of your team members are program STL, TPM, or PMT
                                    (include those names in the columns after "Description of
                                    Support")
                                </li>
                            </ol>
                        </Box>
                    }
                >
                    <DirectoryList
                        isLoading={isLoading}
                        fetchData={fetchData}
                        myTeams={myTeams}
                        allTeams={allTeams}
                        isAllTeams={isAllTeams}
                        setIsAllTeams={setIsAllTeams}
                        parentPrograms={parentPrograms}
                        revisionOptions={revisionOptions}
                        selectedRevision={selectedRevision}
                        setSelectedRevision={setSelectedRevision}
                        handleClickCreate={() => setCreateModalVisible(true)}
                    />
                    <CreateRevision
                        visible={createModalVisible}
                        setVisible={setCreateModalVisible}
                        handleCreate={handleCreate}
                    />
                </Container>
            </ContentLayout>
        </Box>
    )
}

export default Directory
