import _ from 'lodash'

import './TableStyle.scss'
import { CustomCellRendererProps } from 'ag-grid-react'

export default (props: CustomCellRendererProps, itemName) => {
    const { node } = props

    let itemVal
    if (node.group && node.level >= 0) {
        const children = node?.allLeafChildren
        if (!_.isEmpty(children)) {
            itemVal = children[0].data[itemName]
        }
    }

    return (
        <div>
            {node.group && <>{!node.footer && itemVal}</>}
            {node.rowPinned == 'bottom' && <></>}
        </div>
    )
}
