import React, { useEffect, useState } from 'react'
import { Spinner } from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react/polaris'
import { isAllocationActive } from '../../common/Util'
import { decompressResponse } from './Utils'
import useStore from '../../Store'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import {} from '@amzn/ag-bird/src/ag-grid-enterprise'
import _ from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import { GRAND_TOTAL_COLOR_CODE, NORMAL_CELL_COLOR_CODE } from '../grid/Constants'
import ParentNameCellRenderer from '../grid/ParentNameCellRenderer'
import SecondaryGroupCellRenderer from '../grid/SecondaryGroupCellRenderer'

const ALLOCATION_COLUMN_DEFINITION = {
    headerName: 'Allocation Total',
    field: 'allocation_total',
    sortable: true,
    aggFunc: 'sum',
    valueFormatter: (params) => params.value?.toFixed(2),
}

const SUMMARY_GRID_HEIGHT = 300

const groupTableColDefs: ColDef[] = [
    {
        field: 'group_name',
        hide: true,
        rowGroup: true,
    },
    {
        headerName: 'Group',
        field: 'group',
        showRowGroup: 'group_name',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            innerRenderer: (params) =>
                ParentNameCellRenderer(
                    params,
                    (data) => data?.group_name ?? 'Group',
                    'allocation_total',
                ),
        },
        tooltipValueGetter: (params) => {
            if (!params?.node?.group) {
                return
            }
            const tooltipSuffix = params.node?.footer ? ' Total' : ''
            return `${params.value ?? ''}${tooltipSuffix}`
        },
    },
    {
        headerName: 'Team',
        field: 'team_name',
        sortable: true,
        tooltipField: 'team_name',
    },
    {
        field: 'team_id',
        hide: true,
    },
    ALLOCATION_COLUMN_DEFINITION,
]

const programTableColDefs: ColDef[] = [
    {
        field: 'local_program_id_and_year',
        rowGroup: true,
        hide: true,
        sortable: false,
    },
    {
        headerName: 'Year',
        field: 'program_year',
        showRowGroup: 'local_program_id_and_year',
        cellRenderer: (params) => SecondaryGroupCellRenderer(params, 'program_year'),
        valueGetter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return
            }
            return children[0]?.data?.program_year
        },
        valueFormatter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return ''
            }
            return children[0]?.data?.program_year ?? 'Year'
        },
    },
    {
        headerName: 'Program',
        field: 'program_name',
        showRowGroup: 'local_program_id_and_year',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            innerRenderer: (params) =>
                ParentNameCellRenderer(
                    params,
                    (data) => `${data?.program_name ?? 'Program'}`,
                    'allocation_total',
                ),
        },
        valueGetter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return
            }
            return `${children[0]?.data?.program_name}`
        },
        valueFormatter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return ''
            }
            return children[0]?.data?.program_name ?? 'Program'
        },

        tooltipValueGetter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (!params.node?.group || !children.length) {
                return
            }
            const programName =
                params.node?.footer && params.node?.level === -1
                    ? ''
                    : `${children[0]?.data?.program_name}`
            const tooltipSuffix = params.node?.footer ? ' Total' : ''
            return `${programName ?? ''}${tooltipSuffix}`
        },
    },
    {
        headerName: 'Project',
        field: 'project_name',
        tooltipField: 'project_name',
    },
    ALLOCATION_COLUMN_DEFINITION,
]

const defaultGridOptions = (): GridOptions => {
    return {
        groupDisplayType: 'custom',
        groupTotalRow: 'bottom',
        grandTotalRow: 'bottom',
        autoSizeStrategy: {
            type: 'fitGridWidth',
        },
        getRowStyle: (params) => {
            return {
                fontWeight:
                    params.node.rowPinned || params.node.footer /*|| params.node*/
                        ? 'bold'
                        : 'normal',
                background: params.node.footer ? GRAND_TOTAL_COLOR_CODE : NORMAL_CELL_COLOR_CODE,
            }
        },
        pagination: false,
        groupLockGroupColumns: 2,
        sortingOrder: ['asc', 'desc'],
        suppressAggFuncInHeader: true,
    }
}

const DEFAULT_COLUMN_OPTIONS: ColDef = {
    suppressMovable: true,
}
interface SummaryTableProps {
    setGridApi: (api: GridApi) => void
    rowData: any[]
    colDefs: ColDef[]
}
const SummaryTable = (props: SummaryTableProps) => {
    const { rowData, setGridApi, colDefs } = props

    return (
        <div className='ag-theme-quartz' style={{ height: SUMMARY_GRID_HEIGHT }}>
            <AgGridReact
                onGridReady={(params) => setGridApi(params.api)}
                columnDefs={colDefs}
                rowData={rowData}
                gridOptions={defaultGridOptions()}
                defaultColDef={DEFAULT_COLUMN_OPTIONS}
            />
        </div>
    )
}

const AllocationSummary = (props) => {
    const { effectiveDate, isActive, itemType, itemId, originalIsActive } = props
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [itemAllocations, setItemAllocations] = useState([])
    const [isAllocationLoading, setIsAllocationLoading] = useState(false)
    const localProgramMap = useStore((state) => state.localProgramsByYear)
    const setLocalProgramMap = useStore((state) => state.setLocalProgramsByYear)
    const projectMap = useStore((state) => state.projectsByYear)
    const setProjectMap = useStore((state) => state.setProjectsByYear)
    const teamMap = useStore((state) => state.teamMap)
    const setTeamMap = useStore((state) => state.setTeamMap)
    const [programAllocationSummaryData, setProgramAllocationSummaryData] = useState({})
    const [groupAllocationSummaryData, setGroupAllocationSummaryData] = useState({})
    const [isTeamsLoading, setIsTeamsLoading] = useState(false)
    const [isProgramsLoading, setIsProgramsLoading] = useState(false)
    const [isProjectsLoading, setIsProjectsLoading] = useState(false)
    const [activeItemAllocations, setActiveItemAllocations] = useState([])
    const [programsGrid, setProgramsGrid] = useState<GridApi>()
    const [groupsGrid, setGroupsGrid] = useState<GridApi>()

    const fetchLocalProgramsByBusinessEntityAndYear = (year, expectedYears) => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${year}/programs`)
            .then((res) => {
                const programIdMap = res.data.map((program) => [program.local_program_id, program])
                setLocalProgramMap({ year: year, values: new Map(programIdMap) })
                setIsProgramsLoading(expectedYears.length < Object.keys(localProgramMap).length + 1)
            })
            .catch((err) => {
                console.error(err)
                setIsProgramsLoading(false)
            })
    }
    const fetchProjectsByBusinessEntityAndYear = (year, expectedYears) => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${year}/projects`)
            .then((res) => {
                const yearProjectMap = res.data.map((project) => [project.project_id, project])
                setProjectMap({ year: year, values: new Map(yearProjectMap) })
                setIsProjectsLoading(expectedYears.length < Object.keys(projectMap).length + 1)
            })
            .catch((err) => {
                console.error(err)
                setIsProjectsLoading(false)
            })
    }

    const fetchTeamsForBusinessEntity = () => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/teams`)
            .then((res) => {
                const teamEntries = res.data.map((team) => [
                    `${team.team_id}#${team.group_id}`,
                    team,
                ])
                setTeamMap(new Map(teamEntries))
                setIsTeamsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsTeamsLoading(false)
            })
    }
    useEffect(() => {
        fetchFalconAllocationsForItem()
    }, [itemType])

    useEffect(() => {
        if (!isActive || (isActive && !originalIsActive)) {
            generateSummaryForDate()
        }
    }, [activeItemAllocations, effectiveDate, isActive, groupsGrid, programsGrid])

    const generateSummaryForDate = () => {
        const programProjectSummary = {}
        const groupTeamSummary = {}
        activeItemAllocations.forEach((allocation) => {
            if (allocation['allocation_week'] < effectiveDate) {
                return
            }
            const allocationValue = Number(allocation['allocation_value'])
            const programMapForYear = localProgramMap[allocation['allocation_year']] ?? new Map()
            const projectMapForYear = projectMap[allocation['allocation_year']] ?? new Map()
            const groupTeamKey = `${allocation['team_id']}#${allocation['group_id']}`

            const foundTeam = teamMap.get(groupTeamKey) ?? {}
            const programProjectKey = `${allocation['local_program_id']}#${allocation['project_id']}`
            const foundProgram = programMapForYear.get(allocation['local_program_id']) ?? {}
            const foundProject = projectMapForYear.get(allocation['project_id']) ?? {}

            if (!programProjectSummary[programProjectKey]) {
                programProjectSummary[programProjectKey] = {
                    program_name: foundProgram?.program_name ?? 'Program',
                    project_name: foundProject?.project_name ?? 'Project',
                    program_year: allocation['allocation_year'],
                    program_id: allocation['program_id'],
                    project_id: allocation['project_id'],
                    local_program_id: allocation['local_program_id'],
                    allocation_total: 0,
                    program: allocation['local_program_id'],
                    local_program_id_and_year: `${allocation['local_program_id']}#${allocation['allocation_year']}`,
                }
            }
            if (!groupTeamSummary[groupTeamKey]) {
                groupTeamSummary[groupTeamKey] = {
                    team_name: foundTeam?.team_name ?? 'Team',
                    group_name: foundTeam?.group_name ?? 'Group',
                    allocation_total: 0,
                    group_id: allocation['group_id'],
                    team_id: allocation['team_id'],
                }
            }
            programProjectSummary[programProjectKey]['allocation_total'] += allocationValue
            groupTeamSummary[groupTeamKey]['allocation_total'] += allocationValue
        })
        setProgramAllocationSummaryData(programProjectSummary)
        setGroupAllocationSummaryData(groupTeamSummary)
    }

    useEffect(() => {
        if (isTeamsLoading || isProgramsLoading || isProjectsLoading) {
            return
        }
        setActiveItemAllocations(
            itemAllocations.filter((allocation) => {
                if (!Number(allocation['allocation_value'])) {
                    return false
                }
                const programMapForYear =
                    localProgramMap[allocation['allocation_year']] ?? new Map()
                const projectMapForYear = projectMap[allocation['allocation_year']] ?? new Map()
                const groupTeamKey = `${allocation['team_id']}#${allocation['group_id']}`

                const foundTeam = teamMap.get(groupTeamKey) ?? {}
                const foundProgram = programMapForYear.get(allocation['local_program_id']) ?? {}
                const foundProject = projectMapForYear.get(allocation['project_id']) ?? {}
                return isAllocationActive(allocation, foundTeam, foundProgram, foundProject)
            }),
        )
        setIsAllocationLoading(false)
    }, [isTeamsLoading, isProgramsLoading, isProjectsLoading, itemAllocations])

    const fetchFalconAllocationsForItem = () => {
        setIsAllocationLoading(true)
        apiClient
            .get(
                `/falcon-allocations/item-type/${itemType}/item-id/${itemId}?date=${''}&business_entity_id=${selectBusinessEntity.id}`,
            )
            .then((res) => {
                const allAllocations = decompressResponse(res.data)
                if (_.isEmpty(teamMap)) {
                    setIsTeamsLoading(true)
                    fetchTeamsForBusinessEntity()
                }

                const allocationYears: Set<string> = new Set(
                    allAllocations.map((alloc) => alloc.allocation_year),
                )
                allocationYears.forEach((year) => {
                    if (!localProgramMap[year]) {
                        setIsProgramsLoading(true)
                        setIsProjectsLoading(true)
                        fetchLocalProgramsByBusinessEntityAndYear(year, allocationYears)
                        fetchProjectsByBusinessEntityAndYear(year, allocationYears)
                    }
                })
                setItemAllocations(allAllocations)
            })
            .catch((err) => {
                console.error(err)
                setIsAllocationLoading(false)
            })
    }

    return isAllocationLoading || isTeamsLoading || isProjectsLoading || isProgramsLoading ? (
        <Spinner />
    ) : (
        <SpaceBetween size={'s'} direction={'vertical'}>
            {
                <Tabs
                    tabs={[
                        {
                            label: `Groups`,
                            id: 'first',
                            content: (
                                <SummaryTable
                                    setGridApi={setGroupsGrid}
                                    rowData={Object.values(groupAllocationSummaryData)}
                                    colDefs={groupTableColDefs}
                                />
                            ),
                        },
                        {
                            label: `Programs`,
                            id: 'second',
                            content: (
                                <SummaryTable
                                    setGridApi={setProgramsGrid}
                                    rowData={Object.values(programAllocationSummaryData)}
                                    colDefs={programTableColDefs}
                                />
                            ),
                        },
                    ]}
                />
            }
        </SpaceBetween>
    )
}

export default AllocationSummary
