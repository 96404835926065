import React, { useEffect, useState } from 'react'
import useStore from '../../Store'
import {
    Alert,
    Button,
    Box,
    Link,
    ExpandableSection,
    Header,
    Modal,
    SpaceBetween,
    Flashbar,
    TextContent,
} from '@amzn/awsui-components-react'
import AllocationAddNewProjectTable from './AllocationAddNewProjectTable'
import { useAppContext } from '../../../context'
import { getTotalByKeyList, getYearFromDate } from '../reusable/AllocationUtils'
import { generateId, isProgramProjectActiveForYear } from '../../common/Util'
import AllocationCreateProjectTable from './AllocationCreateProjectTable'
import { NewProject, createNewProject, getNewProjectChange } from '../project/projectUtil'
import {
    AlertTypes,
    EMPLOYEE_TYPE_GENERIC,
    getDefaultAllocation,
    PROGRAM_ID_SEPARATOR,
} from '../../Constant'
import {
    ADMIN_ACCESS_REQUEST,
    CREATE_PROJECT_REQUEST,
    FALCON_SLACK_CHANNEL,
    SLACK_AMAZON_OPERATIONS,
} from '../../common/LinkUtil'

const AllocationTableHeader = (props) => {
    const { allocationDisabled } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const canAdmin = useStore((state) => state.canAdmin)

    const selectedProjectsToRemove = useStore((state) => state.selectedProjectsToRemove)
    const setSelectedProjectsToRemove = useStore((state) => state.setSelectedProjectsToRemove)
    const selectedProjectsToAdd = useStore((state) => state.selectedProjectsToAdd)
    const setSelectedProjectsToAdd = useStore((state) => state.setSelectedProjectsToAdd)
    const allocationProjects = useStore((state) => state.allocationProjects)
    const setAllocationProjects = useStore((state) => state.setAllocationProjects)
    const allRemainProjects = useStore((state) => state.allRemainProjects)
    const setAllRemainProjects = useStore((state) => state.setAllRemainProjects)
    const setTotalAllocationByEmployeeType = useStore(
        (state) => state.setTotalAllocationByEmployeeType,
    )
    const setTotalAllocation = useStore((state) => state.setTotalAllocation)
    const setAllocationAddProjectAlert = useStore((state) => state.setAllocationAddProjectAlert)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const allocationEndDate = useStore((state) => state.allocationEndDate)
    const actualsYear = getYearFromDate(allocationEndDate)

    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [projects, setProjects] = useState([])
    const [newProjects, setNewProjects] = useState<NewProject[]>([createNewProject()])
    const [createProjectAlertItems, setCreateProjectAlertItems] = useState<object[]>([])

    useEffect(() => {
        if (visible) {
            getAllProjectsByBusinessEntity()
        }
    }, [visible])

    const filterProjectsBySelectedProjects = (targetProjects, selectedProjects) => {
        let tempProjects = [...targetProjects]
        tempProjects = tempProjects.filter(
            (prj1) => !selectedProjects.find((prj2) => prj1.project_id === prj2.project_id),
        )
        return tempProjects
    }

    const handleModalClose = () => {
        setVisible(false)
        setCreateProjectAlertItems([])
        setSelectedProjectsToAdd([])
    }

    const handleAddProjects = () => {
        let tempAllocationProjects = [...allocationProjects]
        tempAllocationProjects = tempAllocationProjects.concat(
            selectedProjectsToAdd.map((prj) => {
                prj.allocation_value = '0'
                prj.allocation_by_employee_type = getDefaultAllocation()
                return prj
            }),
        )
        setAllocationProjects(tempAllocationProjects)
        setSelectedProjectsToAdd([])
        setAllRemainProjects(
            filterProjectsBySelectedProjects(allRemainProjects, selectedProjectsToAdd),
        )
        if (selectedProjectsToRemove.length !== 0) {
            setSelectedProjectsToRemove([])
        }
        setAllocationAddProjectAlert({
            type: 'success',
            content: (
                <TextContent>
                    {`Successfully added project(s) ${selectedProjectsToAdd.map(
                        (prj) => prj.project_name,
                    )} to the allocation table.`}
                </TextContent>
            ),
        })
        setVisible(false)
    }

    const handleRemoveProject = () => {
        const tempAllocationProjects = filterProjectsBySelectedProjects(
            allocationProjects,
            selectedProjectsToRemove,
        )
        setAllocationProjects(tempAllocationProjects)
        setTotalAllocationByEmployeeType(
            Object.fromEntries(
                EMPLOYEE_TYPE_GENERIC.map((employeeType) => [
                    employeeType,
                    getTotalByKeyList(
                        tempAllocationProjects,
                        `allocation_by_employee_type.${employeeType}`,
                    ),
                ]),
            ),
        )
        setTotalAllocation(getTotalByKeyList(tempAllocationProjects, 'allocation_value'))
        const tempAllRemainProjects = [...allRemainProjects]
        tempAllRemainProjects.concat(selectedProjectsToRemove)
        setAllRemainProjects(tempAllRemainProjects)
        setSelectedProjectsToRemove([])
        if (selectedProjectsToAdd.length !== 0) {
            setSelectedProjectsToAdd([])
        }
        setAllocationAddProjectAlert({
            type: 'success',
            content: `Successfully removed project(s) ${selectedProjectsToRemove.map(
                (prj) => prj.project_name,
            )} from the allocation table.`,
        })
    }

    const getAllProjectsByBusinessEntity = () => {
        if (!actualsYear) {
            console.error(
                `Invalid actuals year ${actualsYear}, full date ${allocationEndDate}, cannot fetch projects.`,
            )
            setAllRemainProjects([])
            return
        }
        setIsLoading(true)
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${actualsYear}/projects`)
            .then((response) => {
                const allProjects = response.data
                setAllRemainProjects(getAllRemainProjectsList(allProjects))
                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const getAllRemainProjectsList = (allProjects: any) => {
        return allProjects.filter(
            (proj) =>
                !allocationProjects.find(({ project_id }) => proj.project_id === project_id) &&
                isProgramProjectActiveForYear(proj),
        )
    }

    const setAlertItemsTemplate = (type: string, content: string) => {
        setCreateProjectAlertItems([
            {
                type: type,
                content: content,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setCreateProjectAlertItems([]),
            },
        ])
    }

    const handleNewProjectChange = (id: string, type: string, value: string) => {
        setNewProjects(getNewProjectChange(newProjects, id, type, value))
    }

    const handleNewProjectReset = () => {
        setNewProjects([createNewProject()])
    }

    const generateProjectPayload = () => {
        const projectPayload: any[] = []
        for (const project of newProjects) {
            if (
                !project.projectName ||
                project.projectNameError ||
                !project.selectedProgram.value
            ) {
                return []
            }

            const programIds = project.selectedProgram.value
                ? project.selectedProgram.value.split(PROGRAM_ID_SEPARATOR)
                : ['', '']
            projectPayload.push({
                business_entity_id: selectBusinessEntity.id,
                program_id: programIds[0],
                project_id: generateId(),
                project_name: project.projectName.trim(),
                local_program_id: programIds[1],
                is_active: project.isActive ? 'True' : 'False',
                description: project.description,
                program_name: project.selectedProgram.label,
            })
        }

        return projectPayload
    }

    const createProjects = (payload) => {
        return apiClient.post(`/projects/bulk-create`, JSON.stringify(payload))
    }

    const handleProjectsCreation = () => {
        const payload = { requester: userAlias }
        payload['projects'] = generateProjectPayload()

        if (payload['projects'].length === 0) {
            setAlertItemsTemplate(AlertTypes.ERROR, 'Project is invalid.')
            return
        }

        setIsLoading(true)
        createProjects(payload)
            .then(() => {
                const newSelectProjectsToAdd = [...selectedProjectsToAdd]
                const newAllRemainProjects = [...allRemainProjects]
                newSelectProjectsToAdd.push(payload['projects'][0])
                newAllRemainProjects.push(payload['projects'][0])
                setSelectedProjectsToAdd(newSelectProjectsToAdd)
                setAllRemainProjects(newAllRemainProjects)
                handleNewProjectReset()
                setAlertItemsTemplate(
                    AlertTypes.SUCCESS,
                    `Successfully created and select project ${newProjects[0].projectName}.`,
                )
            })
            .catch((error) => {
                setAlertItemsTemplate(
                    AlertTypes.ERROR,
                    `Failed to create project ${newProjects[0].projectName}: ${error.response.data}.`,
                )
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Box>
            <Modal
                onDismiss={() => handleModalClose()}
                visible={visible}
                closeAriaLabel='Close modal'
                size='max'
                header='Add / Select Project(s)'
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => handleModalClose()}>
                                Cancel
                            </Button>
                            <Button
                                variant='primary'
                                onClick={handleAddProjects}
                                disabled={selectedProjectsToAdd.length === 0}
                                data-cy-allocation={'Select Projects'}
                            >
                                Select Project(s)
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <SpaceBetween direction='vertical' size='s'>
                    <Flashbar items={createProjectAlertItems} />
                    <Alert
                        type={AlertTypes.INFO}
                        header='Cannot find your project after searching?'
                    >
                        {!canAdmin &&
                        !selectBusinessEntity.configurations.project.non_admin_creation ? (
                            <SpaceBetween direction='horizontal' size='xxs'>
                                Contact
                                <Link
                                    external
                                    externalIconAriaLabel='Opens in a new tab'
                                    href={
                                        SLACK_AMAZON_OPERATIONS +
                                        selectBusinessEntity.slack_channels[0].slack_channel_id
                                    }
                                >
                                    #{selectBusinessEntity.slack_channels[0].slack_channel_name}
                                </Link>
                                {`for more information, request new project `}
                                <Link
                                    external
                                    externalIconAriaLabel='Opens in a new tab'
                                    href={CREATE_PROJECT_REQUEST}
                                >
                                    here
                                </Link>
                                , or request admin access
                                <Link
                                    external
                                    externalIconAriaLabel='Opens in a new tab'
                                    href={ADMIN_ACCESS_REQUEST}
                                >
                                    here
                                </Link>
                                .
                            </SpaceBetween>
                        ) : (
                            <SpaceBetween direction='horizontal' size='xxs'>
                                Click "Create Project" below and create one, direct to projects page
                                <Link
                                    external
                                    externalIconAriaLabel='Opens in a new tab'
                                    href={'/projects'}
                                >
                                    here
                                </Link>
                                , or contact
                                <Link
                                    external
                                    externalIconAriaLabel='Opens in a new tab'
                                    href={FALCON_SLACK_CHANNEL}
                                >
                                    #falcon-resource-allocation-help-and-requests
                                </Link>
                                for more information.
                            </SpaceBetween>
                        )}
                    </Alert>
                    {!canAdmin &&
                    !selectBusinessEntity.configurations.project.non_admin_creation ? (
                        <></>
                    ) : (
                        <ExpandableSection header='Create Project'>
                            <AllocationCreateProjectTable
                                projects={projects}
                                newProjects={newProjects}
                                onNewProjectChange={handleNewProjectChange}
                                onNewProjectReset={handleNewProjectReset}
                                onProjectsCreation={handleProjectsCreation}
                            />
                        </ExpandableSection>
                    )}
                    <AllocationAddNewProjectTable modalVisible={visible} isLoading={isLoading} />
                </SpaceBetween>
            </Modal>
            <Header
                actions={
                    <SpaceBetween size='s' direction='horizontal'>
                        <Button
                            onClick={() => setVisible(true)}
                            disabled={allocationDisabled()}
                            data-cy-allocation={'Add / Select Project'}
                        >
                            Add / Select Project(s)
                        </Button>
                        <Button
                            onClick={handleRemoveProject}
                            disabled={selectedProjectsToRemove.length === 0 || allocationDisabled()}
                            data-cy-allocation={'Remove Projects'}
                        >
                            Remove Project(s)
                        </Button>
                    </SpaceBetween>
                }
            ></Header>
        </Box>
    )
}

export default AllocationTableHeader
