import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { RowEvent } from '@amzn/ag-bird/src/ag-grid-enterprise'
import './TableStyle.scss'
import { CustomCellRendererProps } from 'ag-grid-react'

export default (props: CustomCellRendererProps, formatName, totalField) => {
    const { node } = props

    let itemName
    if (node.group && node.level >= 0) {
        const children = node?.allLeafChildren
        if (!_.isEmpty(children)) {
            itemName = formatName(children[0].data)
        }
    }
    const [expanded, setExpanded] = useState<boolean>(node.expanded ?? false)

    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node.expanded)

        node.addEventListener('expandedChanged', expandListener)

        return () => {
            node.removeEventListener('expandedChanged', expandListener)
        }
    }, [])

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])
    return (
        <div>
            {node.group && (
                <>
                    <div
                        style={{
                            cursor: 'pointer',
                            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            display: 'inline-block',
                        }}
                        onClick={onClick}
                    ></div>
                    &nbsp;
                    <span className={node.footer ? 'ag-row-group-leaf-indent' : ''}>
                        {node.footer ? (itemName ? `${itemName} Total` : 'Total') : itemName}
                    </span>
                </>
            )}
            {node.rowPinned == 'bottom' && <>{node?.data ? node.data[totalField] : ''}</>}
        </div>
    )
}
