import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    ContentLayout,
    Header,
    Select,
    SpaceBetween,
    Toggle,
} from '@amzn/awsui-components-react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import useStore from '../../Store'
import EmptyState from '../reusable/EmptyState'
import { getDirectoryColumnDefs, DEFAULT_PAGE_SIZE, DIRECTORY_PREFIX } from './DirectoryUtil'

interface DirectoryListProps {
    isLoading: boolean
    fetchData: (url: string, onSuccess: (res: any) => void, errMsg: string) => void
    myTeams: Map<string, any> | undefined
    allTeams: Map<string, any> | undefined
    isAllTeams: boolean
    setIsAllTeams: (isAllTeams: boolean) => void
    parentPrograms: Map<string, any> | undefined
    revisionOptions: any[]
    selectedRevision: any
    setSelectedRevision: (revision: any) => void
    handleClickCreate: () => void
}

const DirectoryList = ({
    isLoading,
    fetchData,
    myTeams,
    allTeams,
    isAllTeams,
    setIsAllTeams,
    parentPrograms,
    revisionOptions,
    selectedRevision,
    setSelectedRevision,
    handleClickCreate,
}: DirectoryListProps) => {
    const canAdmin = useStore((state) => state.canAdmin)

    const [gridApi, setGridApi] = useState<any>(undefined)
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([])
    const [rowData, setRowData] = useState<any[] | undefined>(undefined)

    const onGridReady = (params) => {
        setGridApi(params.api)
        params.api.setGridOption('domLayout', 'autoHeight')
    }

    useEffect(() => {
        setColumnDefs(getDirectoryColumnDefs)
    }, [])

    useEffect(() => {
        if (isLoading) {
            gridApi?.showLoadingOverlay()
        } else if (!gridApi?.getDisplayedRowCount()) {
            gridApi?.showNoRowsOverlay()
        } else {
            gridApi?.hideOverlay()
        }
    }, [isLoading])

    useEffect(() => {
        if (!selectedRevision || !selectedRevision.value) {
            setRowData([])
            return
        }

        const processDirectoryRes = (res: any) => {
            const team_programs = res.data.filter((row) =>
                row.directory_sort.startsWith(DIRECTORY_PREFIX.TEAM_PROGRAM),
            )
            setRowData(team_programs)
        }
        fetchData(
            `/directory/${selectedRevision.value.revision_id}`,
            processDirectoryRes,
            `Fail to get directory revision ${selectedRevision.value.title}`,
        )
    }, [selectedRevision])

    useEffect(() => {
        if (!gridApi) return
        if (
            isLoading ||
            rowData == undefined ||
            myTeams === undefined ||
            allTeams === undefined ||
            parentPrograms == undefined
        )
            return

        const rows = isAllTeams ? rowData : rowData.filter((row) => myTeams.has(row.team_id))
        rows.forEach((row) => {
            row.team_name = allTeams.get(row.team_id)?.team_name
            row.program_name = parentPrograms.get(row.parent_program_id)?.program_name
        })
        gridApi.setGridOption('rowData', rows)
    }, [rowData, isAllTeams, myTeams, allTeams, parentPrograms])

    return (
        <ContentLayout
            header={
                <Header
                    actions={
                        <SpaceBetween size='l' direction='horizontal' alignItems='center'>
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Box>My Teams</Box>
                                <Toggle
                                    checked={isAllTeams}
                                    onChange={({ detail }) => {
                                        setIsAllTeams(detail.checked)
                                    }}
                                    disabled={isLoading}
                                >
                                    All Teams
                                </Toggle>
                            </SpaceBetween>
                            <Button disabled={!rowData || rowData.length == 0}>Export</Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size='m' direction='horizontal' alignItems='center'>
                        <Select
                            selectedOption={selectedRevision}
                            onChange={({ detail }) => {
                                if (!detail.selectedOption.value) {
                                    handleClickCreate()
                                    return
                                }
                                setSelectedRevision(detail.selectedOption)
                            }}
                            options={
                                canAdmin
                                    ? [{ label: 'New Revision', value: null }, ...revisionOptions]
                                    : revisionOptions
                            }
                            disabled={isLoading}
                            placeholder='Select a revision'
                        />
                        {canAdmin && (
                            <>
                                <Button variant='primary' disabled={isLoading || !selectedRevision}>
                                    Close Revision
                                </Button>
                            </>
                        )}
                    </SpaceBetween>
                </Header>
            }
        >
            <div className='ag-theme-quartz' style={{ height: 'fit-content' }}>
                <AgGridReact
                    pagination={true}
                    paginationPageSize={DEFAULT_PAGE_SIZE}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    noRowsOverlayComponent={() =>
                        !selectedRevision || !selectedRevision.value ? (
                            <EmptyState
                                title='No revisions'
                                subtitle="You don't have any revision to display."
                                action={
                                    canAdmin && (
                                        <div style={{ pointerEvents: 'all' }}>
                                            <Button onClick={handleClickCreate}>
                                                Create Revision
                                            </Button>
                                        </div>
                                    )
                                }
                            />
                        ) : (
                            <EmptyState
                                title='No directory items'
                                subtitle="You don't have any directory items to display."
                                action=''
                            />
                        )
                    }
                />
            </div>
        </ContentLayout>
    )
}

export default DirectoryList
